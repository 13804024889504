export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    tribute: "",
    tributes: "",
  },

  getters: {
    tribute: (state) => state.tribute,
    tributes: (state) => state.tributes,
  },

  mutations: {
    settribute: (state, tribute) => (state.tribute = tribute),
    settributes: (state, tributes) => (state.tributes = tributes),
  },

  actions: {
 

    async get_tributes({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_tributes");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/levels?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("settributes", response.data.data);
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async approve_tribute({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "create_user");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/users/create", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setusers", response.data.data);
              toast.success("Tribute Approved Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },


  },
};
