export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    obituary: "",
    obituaries: "",
    locations: "",
  },

  getters: {
    obituary: (state) => state.obituary,
    obituaries: (state) => state.obituaries,
    locations: (state) => state.locations,
  },

  mutations: {
    setobituary: (state, obituary) => (state.obituary = obituary),
    setobituaries: (state, obituaries) => (state.obituaries = obituaries),
    setlocations: (state, locations) => (state.locations = locations),
  },

  actions: {

    async get_locations({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_locations");
      return new Promise((resolve, reject) => {
        axios
          .post("/user/obituaries/locations?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
              commit("setlocations", response.data.res);
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },


    async get_obituaries({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_obituaries");
      return new Promise((resolve, reject) => {
        axios
          .post("/user/obituaries?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
              commit("setobituaries", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async get_obituary_details({ commit }, { data, id }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_obituary_details");
      return new Promise((resolve, reject) => {
        axios
          .post("/user/obituaries/" + id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.res) {
              commit("setobituary", response.data.res);
            }
            resolve(response);
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },


  
  },
};
