export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    condolen: "",
    condolens: "",
  },

  getters: {
    condolen: (state) => state.condolen,
    condolens: (state) => state.condolens,
  },

  mutations: {
    setcondolen: (state, condolen) => (state.condolen = condolen),
    setcondolens: (state, condolens) => (state.condolens = condolens),
  },

  actions: {
    async get_condolens({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_condolens");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/levels?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setcondolens", response.data.data);
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};
