export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    agent: "",
    agents: "",
  },

  getters: {
    agent: (state) => state.agent,
    agents: (state) => state.agents,
  },

  mutations: {
    setagent: (state, agent) => (state.agent = agent),
    setagents: (state, agents) => (state.agents = agents),
  },

  actions: {

    async get_agents({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_agents");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/levels?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setagents", response.data.data);
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};
