export const namespaced = true 
export default {
    state : {
        error :'',
        errors :[],
        apierror:'',
        msg :'',
        loading:'',
        pageloading:'',
        logintypetype:'',
        uploadPercentage:0,
        nav_menu:''
    },
    
    getters : {
        error: (state )=> state.error,
        errors: (state )=> state.errors,
        apierror: (state )=> state.apierror,
        msg: (state )=> state.msg,
        loading: (state )=> state.loading,
        pageloading: (state )=> state.pageloading,
        uploadPercentage: (state )=> state.uploadPercentage,
        nav_menu: (state )=> state.nav_menu,
    },

    

    mutations : { 
        setloader :(state,loading ) => (state.loading = loading), 
        setpageloading :(state,pageloading ) => (state.pageloading = pageloading), 
        seterror :(state,error ) => (state.error = error), 
        seterrors :(state,errors ) => (state.errors = errors), 
        setmsg :(state,msg ) => (state.msg = msg), 
        setapierror :(state,apierror ) => (state.apierror = apierror),
        setuploadPercentage :(state,uploadPercentage ) => (state.uploadPercentage = uploadPercentage),
        setnav_menu :(state,nav_menu ) => (state.nav_menu = nav_menu),
    },


    actions : {

        async clearutils( { commit }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', '')
            commit('setapierror', '')
            commit('seterrors', '')
            commit('setpageloading', '')
        },


        async changenav_menu( { commit }, value) {
            commit('setnav_menu', value)
        },





    },

    
}