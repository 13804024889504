<template>
  <header class="page_header ls s-bordertop home-header nav-narrow s-py-5 s-py-lg-5" style="background-color: #141E3C;">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-7">
          <Router-link to="/">
            <a href="" class="logo">
              <img src="images/logo.png" alt="" />
              <div class="d-flex flex-column">
                <h4 class="logo-text color-main">Resttheewell</h4>
                <span class="logo-subtext">Obituary Centre</span>
              </div>
            </a>
          </Router-link>
        </div>
        <div class="col-xl-5">
          <div class="nav-wrap with-top-buttons">
            <!-- main nav start -->
            <nav class="top-nav">
              <ul class="nav sf-menu">
                <li class="active">
                  <a href="index.html"><h3 style="font-size: 15px; text-transform: uppercase;">HOME</h3></a>
                </li>

                <!-- eof pages -->
                <!-- blog -->
                <li>
                  <a href="#about"><h3 style="font-size: 16px; text-transform: uppercase;">Obituaries</h3></a>
                </li>
                <!-- eof blog -->
              

                <li>
                  <a href="#our-news"><h3 style="font-size: 16px; text-transform: uppercase;">Sympathy & Grief</h3></a>
                </li>
              
      

                <!-- eof contacts -->
              </ul>
            </nav>
            <a href="#" style="font-size: 16px; text-transform: uppercase;" class="d-none text-white d-md-block btn btn-small btn-maincolor"><h3 style="font-size: 16px; text-transform: uppercase;">Submit Obituary</h3></a>
          </div>
        </div>
      </div>
    </div>

    <!-- header toggler -->

    <span class="toggle_menu main-toggle"><span></span></span>
  </header>
</template>
<script></script>
