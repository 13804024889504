<template>
<router-view></router-view>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import Navbar from './views/homecomponents/Navbar.vue'
import compfooter from './views/homecomponents/compfooter.vue'
export default{
    components:{
        Navbar,
        compfooter
    },
    methods:{
        ...mapActions(['getuserlocation','getuseripaddress'])
    },

    created(){
        console.log('sdfsdfsd')
        this.getuseripaddress().then(()=>{
            this.getuserlocation();
        })
    }
}
</script>

