<template>
  <div>
    <footer
      class="page_footer text-center text-md-left page_footer ds s-pt-55 s-pb-0 s-pt-lg-95 s-pb-lg-30 s-pt-xl-145 s-pb-xl-80 c-gutter-50"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-xl-3 animate" data-animation="fadeInUp">
            <div class="widget widget_text">
              <a href="index-2.html" class="logo">
                <img src="images/logo.png" alt="" />
                <div class="d-flex flex-column">
                  <h4 class="logo-text color-main">Memento</h4>
                  <span class="logo-subtext">Funeral Service</span>
                </div>
              </a>
              <p>
                We do accept cremains from throughout the United States and the
                world. Many of our families come from the Phoenix area as well
                as various
              </p>
            </div>
          </div>

          <div class="col-md-6 col-xl-3 animate" data-animation="fadeInUp">
            <div class="widget widget_working_hours">
              <h3>Our Hours</h3>
              <ul class="list-bordered">
                <li class="row border-top-0">
                  <div class="col-6">Weekdays</div>
                  <div class="col-6 text-md-right">
                    <span class="color-darkgrey">9:00 - 17:00</span>
                  </div>
                </li>

                <li class="row">
                  <div class="col-6">Saturday</div>
                  <div class="col-6 text-md-right">
                    <span class="color-darkgrey">9:00 - 17:00</span>
                  </div>
                </li>

                <li class="row border-bottom-0">
                  <div class="col-6">Sunday</div>
                  <div class="col-6 text-md-right">
                    <span class="color-darkgrey">Closed</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-6 col-xl-3 animate" data-animation="fadeInUp">
            <div class="widget widget_mailchimp">
              <h3 class="widget-title">Newsletter</h3>

              <p>
                Stay updated with recent news.
                <br />
                We promise not to spam!
              </p>

              <form
                class="signup"
                action="https://html.modernwebtemplates.com/"
              >
                <label for="mailchimp_email">
                  <span class="screen-reader-text">Subscribe:</span>
                </label>

                <input
                  id="mailchimp_email"
                  name="email"
                  type="email"
                  class="form-control mailchimp_email"
                  placeholder="Your Email"
                />

                <button type="submit" class="search-submit">
                  <span class="screen-reader-text">Subscribe</span>
                </button>
                <div class="response"></div>
              </form>
            </div>
          </div>

          <div class="col-md-6 col-xl-3 animate" data-animation="fadeInUp">
            <div class="widget widget_icons_list">
              <h3>Contacts</h3>

              <div class="media side-icon-box">
                <div class="icon-styled color-main fs-14">
                  <i class="ico-maps"></i>
                </div>
                <p class="media-body">
                  808 Pickens Way<br />
                  Cooper, TX 75432
                </p>
              </div>
              <div class="media side-icon-box">
                <div class="icon-styled color-main fs-14">
                  <i class="ico-phone"></i>
                </div>
                <p class="media-body">1-800-123-45-67</p>
              </div>
              <div class="media side-icon-box">
                <div class="icon-styled color-main fs-14">
                  <i class="fa fa-envelope"></i>
                </div>
                <p class="media-body">
                  <a
                    href="https://html.modernwebtemplates.com/cdn-cgi/l/email-protection#04696169616a706b44617c65697468612a676b69"
                    ><span
                      class="__cf_email__"
                      data-cfemail="8ee3ebe3ebe0fae1ceebf6efe3fee2eba0ede1e3"
                      >[email&#160;protected]</span
                    ></a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <section class="page_copyright ls s-py-20">
      <div class="container">
        <div class="row align-items-center">
          <div
            class="col-md-6 text-center text-md-left animate"
            data-animation="fadeInUp"
          >
            <p>
              &copy; Memento <span class="copyright_year">2019</span> - All
              Rights Reserved
            </p>
          </div>
          <div
            class="col-md-6 text-center text-md-right animate"
            data-animation="fadeInUp"
          >
            <span class="social-icons">
              <a href="#" class="fa fa-facebook" title="facebook"></a>
              <a href="#" class="fa fa-paper-plane fs-14" title="telegram"></a>
              <a href="#" class="fa fa-linkedin" title="linkedin"></a>
              <a href="#" class="fa fa-instagram" title="instagram"></a>
              <a href="#" class="fa fa-youtube-play" title="youtube-play"></a>
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
