import store from '@/store'
const adminguard = (to, from, next) => {
  return next();
  // if(localStorage.getItem('resttheewell' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.role == 'admin'){
  //     return next()
  // }else{
  //     return next({ name: 'login' })
  // }
};

let routes = [
  {
    path: "/",
    props: true,
    beforeEnter: adminguard,
    component: () => import("@/views/app/main.vue"),
    title: "",

    children: [
      {
        path: "/",
        name: "welcome",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/dash.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },


      {
        path: "/obituaries",
        name: "obituaries",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/Obituaries/obituaries.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
      {
        path: "/obituaries/:id",
        name: "obituary_details",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/Obituaries/obituary_details.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },


      //grief
      {
        path: "/sympathy-and-grief",
        name: "sympathy_and_grief",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/grief/grief.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },

      //grief
      {
        path: "/find-agents",
        name: "find_agents",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/agents/agents.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
      
    ]
  }
    
];
export default routes;
