import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import agents from './modules/agents'
import utils  from './modules/utils'
import condolens from './modules/condolens'
import users from './modules/users'
import tributes from './modules/tributes'
import obituarys from './modules/obituaries';
import familys from   './modules/familys'

export default createStore({
  modules: {
    auth, 
    utils,
    agents,
    users,
    condolens,
    tributes,
    obituarys,
    familys
  },
    
  plugins: [createPersistedState(
    {
      key : 'smarttraderuganda.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
